
import * as vercelRuntime$jfk6mQUR7Y from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.5.0_rollup@4.14.1/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 360,
    "sm": 414,
    "md": 768,
    "lg": 1366,
    "xl": 1536,
    "xxl": 1536,
    "2xl": 1920
  },
  "presets": {},
  "provider": "vercel",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2,
    1,
    2
  ],
  "format": [
    "webp",
    "avif",
    "jpeg",
    "jpg",
    "png",
    "gif",
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$jfk6mQUR7Y, defaults: {} }
}
        